<template>
  <div class="welcome">
    <van-swipe
      ref="slider"
      :loop="false"
      @change="swipeChange"
    >
      <van-swipe-item
        v-for="slide in slides"
        :key="slide.title"
      >
        <onboarding-slide v-bind="slide" />
      </van-swipe-item>
    </van-swipe>
    <div class="welcome__navigation">
      <van-button
        type="plain"
        :text="$t('continue')"
        :aria-label="$t('next')"
        class="welcome__next"
        @click="next()"
      />
      <van-button
        type="plain"
        :to="{ name: 'login' }"
        :text="$t('skip')"
        :aria-label="$t('skip')"
        class="welcome__skip"
      />
    </div>
  </div>
</template>

<script>
import OnboardingSlide from '@/components/OnboardingSlide';

const Image = (image) => `/img/onboarding/${image}.png`;

export default {
  components: {
    OnboardingSlide,
  },
  data() {
    return {
      index: 0,
      slides: [
        {
          title: this.$i18n.t('onboarding.slides.pheOverview.title'),
          text: this.$i18n.t('onboarding.slides.pheOverview.text'),
          image: Image('pheOverview'),
        },
        {
          title: this.$i18n.t('onboarding.slides.calendarView.title'),
          text: this.$i18n.t('onboarding.slides.calendarView.text'),
          image: Image('calendarView'),
        },
        {
          title: this.$i18n.t('onboarding.slides.favorites.title'),
          text: this.$i18n.t('onboarding.slides.favorites.text'),
          image: Image('favorites'),
        },
        {
          title: this.$i18n.t('onboarding.slides.aminoAcid.title'),
          text: this.$i18n.t('onboarding.slides.aminoAcid.text'),
          image: Image('aminoAcid'),
        },
        {
          title: this.$i18n.t('onboarding.slides.swipeGestures.title'),
          text: this.$i18n.t('onboarding.slides.swipeGestures.text'),
          image: Image('swipeGestures'),
        },
      ],
    };
  },
  created() {
    if (this.isIOSDevice()) {
      this.slides.push(
        {
          title: this.$i18n.t('onboarding.slides.iosInstallation.title'),
          text: this.$i18n.t('onboarding.slides.iosInstallation.text'),
          image: Image('iosInstallation'),
        },
      );
    }
  },
  methods: {
    swipeChange(index) {
      this.index = index;
    },
    next() {
      if (this.index >= (this.slides.length - 1)) {
        this.$router.push({ name: 'login' });
      }
      this.$refs.slider.next();
    },
    isIOSDevice() {
      return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.welcome {
  position: relative;
  height: 100vh;

  .van-swipe__indicators {
    bottom: 7.8rem;
  }

  .van-swipe__indicator {
    background-color: rgba(81, 51, 139, 0.5);
    width: 8px;
    height: 8px;
    margin: 0 4px;

    &--active {
      background-color: rgb(81, 51, 139);
    }
  }
}

.welcome__navigation {
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
  padding: 0 20px;

  .van-button {
    border: 0;
    color: #000;
    width: 100%;
    height: 40px;
    margin-block: 0.5rem;
    &.welcome__next {
      border: 1.5px solid config.$brand-secondary;
      border-radius: 1.25rem;
    }
  }
}

.welcome__prev {
  margin-left: config.$spacing;
}

</style>
