<template>
  <div class="onboarding-slide">
    <div class="onboarding-slide__preview">
      <img :src="image">
    </div>
    <div class="onboarding-slide__content">
      <h3 class="onboarding-slide__title">
        {{ title }}
      </h3>
      <div class="onboarding-slide__text">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    text: { type: String, required: true },
    image: { type: String, required: true },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.onboarding-slide {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.onboarding-slide__preview {
  padding: clamp(1rem, 10vw, 4rem) clamp(2rem, 5vw, 4rem);
  max-width: 600px;
  img {
    max-width: 100%;
    height: auto;
  }
}

.onboarding-slide__content {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 300px;
  padding: 1.53em config.$spacing 1em;
  background-color: #fff;
  box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.15);
  color: config.$color-dark;
  font-size: 0.9375rem;
  line-height: 1.333;
  text-align: center;
}

.onboarding-slide__title {
  font-weight: bold;
}

.onboarding__skip {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: none;
}
</style>
